import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonDataService } from './common-data.service';
import { catchError, map } from 'rxjs/operators';
import { Opportunities } from './user-registration-report/Opportunities';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,private CommonService: CommonDataService) { }

  private loginUrl = `${this.CommonService.getApiLoginUrl()}Auth/login`;
  private isAdminAccessUrl = `${this.CommonService.getApiUrl()}User/CheckUserAdminAccess`;

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { username: username, password: password };
  
    return this.http.post<any>(this.loginUrl, body, { headers, observe: 'response' }).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle specific status codes like 401 Unauthorized
        if (error.status === 401) {
          console.error('Authentication failed: Unauthorized');
        } else if (error.status === 400) {
          console.error('Bad Request:', error.error);
        } else if (error.status === 0) {
          console.error('Network error or API is unreachable');
        } else {
          console.error(`Unexpected error (status code: ${error.status})`, error.error);
        }
  
        // Return a detailed error message for further handling
        return throwError(() => new Error(error.message || 'Unknown authentication error occurred'));
      })
    );
  };

  IsAdminAccess(uid: number) : Observable<any> {
    const apiUrl = `${this.CommonService.getApiUrl()}User/CheckUserAdminAccess/${uid}`;
    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        if (response === true) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error) => {
        console.error('Error fetching user data:', error);
        return throwError(() => new Error('Error fetching user data'));
      })
    );
  };

 

  // Optionally, add methods to handle token storage
  setToken(token: string): void {
    localStorage.setItem('authToken', token);
  }

  getToken(): string | null {
    return localStorage.getItem('authToken');
  }

  getProtectedData(): Observable<Opportunities[]> {
    const token = this.getToken();
  
    if (!token) {
      throw new Error('No token found');
    }
  
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`, // Pass the token in the Authorization header
      'Content-Type': 'application/json'
    });
  
    const apiUrl = `${this.CommonService.getApiUrl()}Facility/GetOpportunities`;
  
    return this.http.get<Opportunities[]>(apiUrl, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error fetching protected data:', error);
    
        if (error.status === 401) {
          console.error('Unauthorized access - Invalid or expired token');
        } else if (error.status === 403) {
          console.error('Forbidden - You don’t have permission to access this resource');
        } else if (error.status === 0) {
          console.error('Network error or CORS issue');
        } else {
          console.error(`Error ${error.status}: ${error.message}`);
        }
    
        // Handle the error and return a fallback or rethrow
        return throwError(() => new Error(error.message || 'Error fetching data'));
      })
    );
  };
  
}

